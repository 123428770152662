import React from 'react';
import Layout from '../components/dfa-theme/layout';
import ContentBlock from '../components/content-block';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Quote from '../components/quote';
import hero_bg from '../images/0.0_MonickerHeartbeat.svg';
import { CONSTANTS } from '../utils/constants';
import BadgeComponent from '../components/badge-component';
import team from '../images/0.0_careers_banner.webp';
import connections from '../images/3.0_Culture_Connections.webp';
import improvement from '../images/3.0_Culture_Improvement.webp';
import community from '../images/3.0_Culture_Community.webp';
import socials from '../images/3.0_Culture_Socials.webp';
import '../scss/pages/culture.scss';

const Culture = () => {
	const metaTags = {
		name: 'Culture',
		description: 'Deerfield Culture',
		keywords: 'Deerfield, Culture',
	};

	return (
		<Layout meta={metaTags}>
			<div className='culture-hero-container'>
				<ContentBlock
					outerBgColor={CONSTANTS.BROWN}
					className='solutions block'
				>
					<Grid>
						<Row>
							<Col xs={1} md={2}></Col>
							<Col xs={10} md={8} className='culture-content title-block'>
								<div className='culture-copy'>
									<p className='header-orange'>culture</p>
									<h1>Unbiased&ndash;it's kind of our brand</h1>
									<p className='header-copy'>
										Deerfield was founded by seasoned experts whose unbiased
										collaboration set a new marketing standard &ndash; one that
										redefined the agency model and eliminated unnecessary
										inefficiencies. Thus, the world's first and only Agency of
										Brand<sup>®</sup> was born.
									</p>
								</div>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
					</Grid>
				</ContentBlock>
			</div>

			<div className='culture'>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
					<Grid>
						<Row>
							<Col xs={1} md={2}></Col>
							<Col xs={10} md={8} className='culture-content title-block'>
								<div className='culture-copy'>
									<p className='intro'>
										What started as a print management company soon evolved into
										more than just an agency. Our unbiased approach means we'll
										become an extension of our client's marketing team to
										deliver smarter, faster, and more creative solutions with
										undeniable results.
									</p>
								</div>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
					<Grid>
						<Row>
							<Col xs={1} md={1}></Col>
							<Col xs={10} md={10}>
								<Quote
									msg={`We do not have to do everything for you, but what we do for you means everything to us.”`}
									from={`Joshua Benson, Founding Partner`}
								/>
							</Col>
							<Col xs={1} md={1}></Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock
					outerBgColor={CONSTANTS.LIGHT_GRAY}
					className='culture-anim-container block'
				>
					<Grid>
						<Row>
							<Col xs={1} md={2}></Col>
							<Col xs={10} md={8} className='culture-anim-block'>
								<div className='culture-copy'>
									<h2 className='blue'>The status quo doesn't cut it</h2>
									<p className='culture-copy'>
										In our oversaturated, fast-paced world, it takes something
										different to make people stop and take a second look. That's
										where we come in. Deerfield knows healthcare by heart. By
										becoming a brand's unbiased marketing partner, we're
										committing to do whatever it takes to deliver our expertise
										to clients and break them free from the status quo.
									</p>
								</div>
								<object
									type='image/svg+xml'
									data={hero_bg}
									className='culture-animation'
									aria-label='logo animation'
								>
									logo-animation
								</object>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
					<Grid>
						<Row>
							<Col xs={1} md={2}></Col>
							<Col xs={10} md={8}>
								<div className='mission-copy'>
									<p className='header-orange dark'>mission</p>
									<h1 className='skinny'>A human-inspired healthcare agency</h1>
									<p className='dark-copy culture-copy skinny'>
										Our mission is to elevate the standard of healthcare
										marketing, and that work starts at home. By equipping our
										employees with the skills and benefits they need to succeed,
										we're dedicated to nurturing them both professionally and
										personally. After all, helping people live healthier lives
										is what we do.
									</p>
								</div>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
						<Row>
							<Col xs={12}>
								<img
									src={team}
									alt='Group shot of the Deerfield team.'
									className='full-width mobile-spacing'
								/>
							</Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='mission block'>
					<Grid>
						<Row>
							<Col xs={1} md={1}></Col>
							<Col xs={10} md={10}>
								<Grid>
									<Row className='mobile-reverse'>
										<Col xs={12} md={6}>
											<div className='mission-copy-2col right'>
												<p className='header-orange dark desktop-only'>
													new connections
												</p>
												<h2 className='blue'>We turn passions into careers</h2>
												<p className='dark-copy'>
													The professional success of our employees is
													paramount. By providing them opportunities to build
													external relationships and gain valuable memberships,
													we're committed to helping them expand their networks
													and advance their careers.
												</p>
												<ul>
													<li>College recruitment and networking</li>
													<li>
														Professional group networking and roundtable
														discussions
													</li>
													<li>
														Memberships to healthcare trade organizations{' '}
													</li>
												</ul>
											</div>
										</Col>
										<Col xs={12} md={6}>
											<div className='mission-copy-2col left'>
												<p className='header-orange dark mobile-only'>
													new connections
												</p>
												<img
													src={connections}
													alt='Frank Burrell speaking at a conference.'
													className='full-width'
												/>
											</div>
										</Col>
									</Row>
								</Grid>
							</Col>
							<Col xs={1} md={1} lg={0}></Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='mission block'>
					<Grid>
						<Row className=''>
							<Col xs={1} md={1}></Col>
							<Col xs={10} md={10}>
								<Grid>
									<Row>
										<Col xs={12} md={6}>
											<p className='header-orange dark mobile-only'>
												continuous improvement
											</p>
											<img
												src={improvement}
												alt='Co-workers training a new employee outside.'
												className='full-width'
											/>
										</Col>
										<Col xs={12} md={6}>
											<div className='mission-copy-2col left'>
												<p className='header-orange dark desktop-only'>
													continuous improvement
												</p>
												<h2>Our excellence is homegrown</h2>
												<p className='dark-copy'>
													That's why we've developed avenues to elevate employee
													voices to help them shape their experience and ensure
													that everyone's achievements become part of the
													Deerfield legacy.
												</p>
												<ul>
													<li>
														Personal and professional growth opportunities
													</li>
													<li>Training programs</li>
													<li>Monthly Lunch and Learns</li>
													<li>Well-being initiatives</li>
												</ul>
											</div>
										</Col>
									</Row>
								</Grid>
							</Col>
							<Col xs={1} md={1} lg={0}></Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='mission block'>
					<Grid>
						<Row>
							<Col xs={1} md={1}></Col>
							<Col xs={10} md={10}>
								<Grid>
									<Row className='mobile-reverse'>
										<Col xs={12} md={6}>
											<div className='mission-copy-2col right'>
												<p className='header-orange dark desktop-only'>
													community involvement
												</p>
												<h2>It's about people &ndash; always</h2>
												<p className='dark-copy'>
													We strive to create a culture of unity, support, and
													collaboration &ndash; both in the office and beyond.
													It's what drives our passion to give back to the
													Deerfield communities in a meaningful way.
												</p>
												<ul>
													<li>Employee volunteer days</li>
													<li>Nonprofit board and committee opportunities</li>
													<li> Food drives</li>
													<li>Holiday gift drives</li>
												</ul>
											</div>
										</Col>

										<Col xs={12} md={6}>
											<p className='header-orange dark mobile-only'>
												community involvement
											</p>
											<img
												src={community}
												alt='The Deerfieeld team participating in a community playground clean up.'
												className='full-width'
											/>
										</Col>
									</Row>
								</Grid>
							</Col>
							<Col xs={1} md={1} lg={0}></Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='mission block'>
					<Grid>
						<Row>
							<Col xs={1} md={1}></Col>
							<Col xs={10} md={10}>
								<Grid>
									<Row>
										<Col xs={12} md={6}>
											<p className='header-orange dark mobile-only'>
												socials and celebrations
											</p>
											<img
												src={socials}
												alt='The Deerfield team celebrating St. Patricks day.'
												className='full-width'
											/>
										</Col>
										<Col xs={12} md={6}>
											<div className='mission-copy-2col left'>
												<p className='header-orange dark desktop-only'>
													socials and celebrations
												</p>
												<h2>Healthcare is human &ndash; we celebrate ours </h2>
												<p className='dark-copy'>
													It's not meetings and deadlines all the time. To
													recognize and appreciate the hard work and dedication
													of our employees, our social events aim to remind them
													to step away from their laptops and have some fun with
													their colleagues.
												</p>
												<ul>
													<li>Cheers for Peers</li>
													<li>Lunch with a Leader</li>
													<li>Team-building activities</li>
													<li>Recognition programs</li>
												</ul>
											</div>
										</Col>
									</Row>
								</Grid>
							</Col>
							<Col xs={1} md={1} lg={0}></Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock
					outerBgColor={CONSTANTS.BLUE}
					className='block block-blue'
				>
					<Grid>
						<Row>
							<Col xs={1} md={2}></Col>
							<Col xs={10} md={8} className='callout'>
								<h2>Introducing the dream team </h2>
								<p>
									Great leadership is hard to come by &ndash; but not
									impossible. Get to know the humans behind ours.
								</p>
								<a
									name='firstLink'
									className='dfa-button-orange'
									href='/leadership'
									aria-label='Meet the leadership at Deerfield.'
								>
									meet our leaders{' '}
								</a>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
					</Grid>
				</ContentBlock>
			</div>
			<BadgeComponent isVisible={true} />
		</Layout>
	);
};

export default Culture;
